import { TranslateService } from '@ngx-translate/core';

export class TextChanges {
  public static updateText(translationFile, change, text) {
    const textSplit = text.split('.');
    this.updateTransFile(translationFile, textSplit, change, 0);
  }

  public static updateTransFile(transfile, langPath, change, i) {
    if (i < langPath.length - 1) {
      if (!transfile[langPath[i]]) {
        transfile[langPath[i]] = {};
      }
      this.updateTransFile(transfile[langPath[i]], langPath, change, i + 1);
    } else {
      transfile[langPath[i]] = change;
    }
  }

  public static getTranslation(contentEdit, translationFile, text, translate: TranslateService) {
    if (text) {
      if (contentEdit || translationFile) {
        const textSplit = text.split('.');
        let transHolder = JSON.parse(JSON.stringify(translationFile));
        for (const itm of textSplit) {
          transHolder = transHolder[itm] ? transHolder[itm] : text;
          if (transHolder === text) { return transHolder; }
        }
        return transHolder;
      }
      return translate.instant(text);
    }
    return text;
  }
}

