import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VARIANT } from './gmmButton.enum';

@Component({
  selector: 'gmm-button',
  templateUrl: './gmm-button.component.html',
  styleUrls: ['./gmm-button.component.scss']
})
export class GmmButtonComponent implements OnInit {

  @Input() buttonText = '';
  @Input() hasFrontIcon = false;
  @Input() frontIcon = '';
  @Input() hasRearIcon = false;
  @Input() rearIcon = '';
  @Input() mini = false;
  @Input() disabled = false;
  @Input() noLineBreak = false;
  @Input() variant: VARIANT = VARIANT.primary;
  @Output() buttonClicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.buttonClicked.emit();
  }
}
