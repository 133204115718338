<div *ngIf="footer" class="lay-wrapper lay-wrapper--vpb-l">
  <div class="lay-wrapper lay-wrapper--default" style="display: flex; padding-bottom: 60px; padding-top: 60px; flex-wrap: wrap;">
    <div style="flex: 1; min-width: 200px;">
      <h3
        [contentEditable]="contentEdit"
        [textContent]="getTranslateText(footer.header)"
        (input)="updateText($event.target.textContent, footer.header)">
        {{footer.header}}</h3>
      <p
        [contentEditable]="contentEdit"
        [textContent]="getTranslateText(footer.text)"
        (input)="updateText($event.target.textContent, footer.text)">
        {{footer.text}}</p>
    </div>

    <div style="flex: 5; display: flex; flex-direction: row; min-width: 300px; margin-bottom: 20px;">
      <div *ngFor="let category of footer.links; let i=index;" class="category-container">

        <div>
          <p class="title"
            [contentEditable]="false"
            [textContent]="getTranslateText(category.category)"
            (input)="updateText($event.target.textContent, category.category)">{{category.category | translate}}</p>
          <div class="action-button-container horizontal" *ngIf="contentEdit">
            <gmm-action-button (buttonClicked)="deleteLinkCategory(i)" [icon]="'delete'" [size]="'30px'" [matTooltip]="'Delete link category'" [color]="'accent'"></gmm-action-button>
          </div>
        </div>

        <div *ngFor="let link of category.linksArray; let x=index;">
          <a
            [href]="link.url"
            [contentEditable]="false"
            [textContent]="getTranslateText(link.title)"
            (input)="updateText($event.target.textContent, link.title)">{{link.title | translate}}</a>

          <div *ngIf="contentEdit" class="action-button-container vertical">
            <gmm-action-button (buttonClicked)="deleteLink(i,x)" [icon]="'delete'" [color]="'accent'" [size]="'30px'" [matTooltip]="'Delete link'"></gmm-action-button>
          </div>
        </div>

        <div class="action-button-container horizontal" *ngIf="contentEdit">
          <gmm-action-button (buttonClicked)="newLink(i)" [icon]="'plus'" [size]="'30px'" [matTooltip]="'Add link'"></gmm-action-button>
        </div>
      </div>

      <div class="action-button-container vertical" *ngIf="contentEdit">
        <gmm-action-button (buttonClicked)="newLinkCategory()" [icon]="'plus'" [size]="'45px'" [matTooltip]="'Add link category'"></gmm-action-button>
      </div>
    </div>
    <div style="flex: 1; display: flex; flex-direction: column; justify-content: space-between;">
      <gmm-button
        *ngIf="footer.button.url"
        (buttonClicked)="openMail()"
        variant="primary"
        [hasFrontIcon]="true"
        frontIcon="email"
        [buttonText]="getTranslateText(footer.button.text)"></gmm-button>
      <div class="social-links" style="display: flex;">
        <div *ngFor="let item of footer.socialLinks">
          <a
            *ngIf="item.url"
            [href]="item.url"
            target="_blank">
            <i [class]="'icon icon--' + item.icon"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="contentEdit" style="margin-top: 30px; display: flex;">
  <div class="inputs-container">
    <div *ngFor="let category of footer.links" style="margin-bottom: 30px">
      <!-- <h3>{{category.category | translate}}</h3> -->
      <gmm-input
        [(model)]="category.category"
        [placeholder]="'Category Title'"
        [label]="'Category Title'"
        [name]="'Category Title'"></gmm-input>
        <div style="display: flex; width: 90%;" *ngFor="let link of category.linksArray">
          <gmm-input
          style="flex: 1;"
            [(model)]="link.title"
            [placeholder]="'https://'"
            [label]="'Link Title'"
            [name]="'Link Title'"></gmm-input>
          <gmm-input
          style="margin-left: 8px; flex: 1;"
            [(model)]="link.url"
            [placeholder]="'https://'"
            [label]="getTranslateText(link.title) + ' URL'"
            [name]="link.title + 'URL'"></gmm-input>
        </div>
    </div>
  </div>
  <div class="inputs-container">
    <gmm-input
      [required]="false"
      [(model)]="footer.button.url"
      [placeholder]="'https://.. or mailto:..'"
      [label]="'Button url'"
      [name]="'buttonUrl'"></gmm-input>
    <gmm-input
      [(model)]="footer.button.text"
      [label]="'Button text'"
      [name]="'buttonText'"></gmm-input>
    <gmm-input
      *ngFor="let item of footer.socialLinks"
      [required]="false"
      [(model)]="item.url"
      [placeholder]="'https://'"
      [label]="item.title + ' URL'"
      [name]="item.title + 'URL'"></gmm-input>
  </div>
</div>