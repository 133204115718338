import { shrinkExpandAnimation } from './../../animations/shrinkExpand.animation';
import { Component, Input, OnInit } from '@angular/core';
import { ContentDataService } from 'src/app/general-content-create/services/content.data';
import { TranslateService } from '@ngx-translate/core';
import { TextChanges } from 'src/app/shared/operations/text-changes.operations';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'gmm-links-info',
  templateUrl: './links-info.component.html',
  styleUrls: ['./links-info.component.scss'],
  animations: [shrinkExpandAnimation]
})
export class LinksInfoComponent implements OnInit {
  footer;
  @Input() contentEdit = false;
  @Input() translationFile;

  constructor(
    private contentData: ContentDataService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.contentData.staticContent.subscribe((data) => {
      if (data) {
        this.footer = data.content.footer;
      }
    });
  }

  openMail() {
    window.open(this.footer.button.url, '_blank');
  }

  getTranslateText(text) {
    return this.translate.instant(text);
  }

  updateText(change, text) {
    TextChanges.updateText(this.translationFile, change, text);
  }

  newLinkCategory() {
    this.footer.links.push({
      category: 'content.footer.category-text-' + uuid().split('-')[0],
      linksArray: []
    });
  }

  newLink(index: number): void {
    this.footer.links[index].linksArray.push({
      title: 'content.footer.category-link-' + uuid().split('-')[0],
      url: ''
    });
  }

  deleteLink(categoryIndex: number, linkIndex: number): void {
    this.footer.links[categoryIndex].linksArray.splice(linkIndex, 1);
  }

  deleteLinkCategory(categoryIndex: number): void {
    this.footer.links.splice(categoryIndex, 1);
  }
}
