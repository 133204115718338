import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './directives/tooltip.directive';
import { RouterModule } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NoSanitizePipe } from './pipes/no-sanitize.pipe';
import { ToastrModule } from 'ngx-toastr';
import { NumberFormatterPipe } from './pipes/number-formatter.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SelectImageComponent } from './components/select-image/select-image.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { COMPONENTS } from '@shared/index';
import { MatMenuModule } from '@angular/material/menu';
import { ComingSoonComponent } from './guards/content-data.guard';

const MODULES = [
  RouterModule,
  DragDropModule,
  CommonModule,
  FormsModule,
  // MATERIAL
  MatDialogModule,
  MatIconModule,
  MatTabsModule,
  MatInputModule,
  MatRadioModule,
  MatGridListModule,
  MatSelectModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatSlideToggleModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatProgressSpinnerModule,
  MatMenuModule
];

@NgModule({
  imports: [MODULES, ToastrModule.forRoot(), TranslateModule.forChild()],
  declarations: [
    COMPONENTS,
    SelectImageComponent,
    TooltipDirective,
    NoSanitizePipe,
    NumberFormatterPipe,
    ComingSoonComponent
  ],
  exports: [MODULES, COMPONENTS, NoSanitizePipe, NumberFormatterPipe],
  entryComponents: [ComingSoonComponent]
})
export class SharedModule {}
