import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Stage } from '@shared/helpers/staging';
import { ContentTranslateService } from '@shared/services/content-translate.service';
import { UserService } from '@static/services/user.service';
import { ContentDataService } from 'src/app/general-content-create/services/content.data';
import { NavItemsService } from '../services/nav-items.service';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'gmm-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,
    private navItemsService: NavItemsService,
    private translate: ContentTranslateService,
    public contentData: ContentDataService,
    public user: UserService
  ) {}

  route: string;
  navItems: any[];
  sideMenuMap: Record<string, any[]> = {};
  timedOutCloser: { func: Timeout; index: number };

  languageSelected = 'en';
  isLoggedIn = false;
  currentUser = { FirstName: '', LastName: '' };
  userNameDepartment = '';
  userIsAdmin = false;
  selectedTab = -1;
  darkMode = false;
  settingsData;

  stages = [
    { stage: Stage.Q, title: 'Q' },
    { stage: Stage.INT, title: 'INT' },
    { stage: Stage.PROD, title: 'LIVE' }
  ];

  ngOnInit() {
    this.contentData.settings.subscribe((data) => {
      this.settingsData = data;

      this.navItems = this.navItemsService.getNavItems();
      this.navItemsService.navChange.subscribe(() => {
        this.navItems = this.navItemsService.getNavItems();
      });

      setTimeout(() => this.updateNavBar(), 500); // animation delay
    });
    // const sideMenuEntries = this.navItemsService.getSideMenuNavItems();
    // this.mapSideMenu(sideMenuEntries);

    const dm = localStorage.getItem('darkMode');
    if (dm && (dm === 'on' || dm === 'off')) {
      if (dm === 'on') {
        this.darkMode = true;
        this.toggleTheme('dark');
      }
    }

    const userSettings = localStorage.getItem('userSettings');
    if (userSettings !== null) {
      const lang = JSON.parse(userSettings).language;
      this.languageSelected = lang;
    }
    this.translate.onLangChange.subscribe((lang) => {
      this.languageSelected = lang.lang;
    });
  }

  ngAfterViewInit() {
    this.updateNavBar();
  }

  updateNavBar() {
    const route = this.contentData.getPage();
    for (let i = 0; i < this.navItems.length; i++) {
      const link = this.navItems[i].Href;
      if (link === route) {
        this.selectedTab = i;
      }
    }
  }

  changeStage(stage: Stage): void {
    this.contentData.selectedStage = stage;
    this.router.navigateByUrl('/');
    this.selectedTab = 0;
  }

  changeLanguage(lang) {
    this.translate.use(lang);
  }

  openLink(link) {
    window.open(link);
  }

  logout() {
    this.router.navigate(['']);
  }

  openTab(e) {
    this.selectedTab = e.index;
    this.router.navigate([this.navItems[e.index].Href]);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  navigateTo(navItem): void {
    this.router.navigate([navItem.Href]);
  }

  toggleTheme(mode: 'light' | 'dark'): void {
    if (mode === 'dark') {
      localStorage.setItem('darkMode', 'on');
      this.darkMode = true;
      document.body.setAttribute('data-theme', 'dark');
    } else {
      localStorage.setItem('darkMode', 'off');
      this.darkMode = false;
      document.body.removeAttribute('data-theme');
    }
  }

  mouseEnter(trigger: MatMenuTrigger, index: number): void {
    if (this.timedOutCloser && this.timedOutCloser.index === index) {
      clearTimeout(this.timedOutCloser.func);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger: MatMenuTrigger, index: number): void {
    this.timedOutCloser = {
      func: setTimeout(() => trigger.closeMenu(), 200),
      index
    };
  }

  hasSideMenu(navItem): boolean {
    return !!this.sideMenuMap[navItem.Name];
  }

  // private mapSideMenu(sideMenuEntries): void {
  //   sideMenuEntries.forEach((sideMenuEntry) => {
  //     if (this.sideMenuMap[sideMenuEntry.ParentName]) {
  //       const array: any[] = this.sideMenuMap[sideMenuEntry.ParentName];
  //       array.push(sideMenuEntry);
  //       this.sideMenuMap[sideMenuEntry.ParentName] = array;
  //     } else {
  //       this.sideMenuMap[sideMenuEntry.ParentName] = [sideMenuEntry];
  //     }
  //   });
  // }
}
