<div class="lay-wrapper lay-wrapper--vpb-l related-content-container" >
    <div class="lay-wrapper lay-wrapper--default">
        <div style="padding-top: 40px;">
          <div class="divider"></div>
          <h3 style="color: white;">Related Content</h3>
          <div style="display: grid; grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); column-gap: 15px; row-gap: 15px; position: relative">
            <div class="related-content-item" *ngFor="let item of relContent" [ngStyle]="{background: 'url(' + item.img + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', 'width': '100%', 'height': '180px'}">
              <div class="related-content-text">
                <h4 style="margin-bottom: 8px; margin-top: 8px;">{{getTranslateText(item.headline)}}</h4>
                <p>{{getTranslateText(item.text)}}</p>
              </div>
              <i class="material-icons direction">keyboard_arrow_right</i>
              <div class="related-content-overlay"></div>
            </div>
          </div>
        </div>
    </div>
</div>

<div *ngIf="contentEdit" cdkDropList (cdkDropListDropped)="drop($event, relContent)">
  <div style="display: flex; flex-direction: row; padding: 10px; background-color: rgba(0, 0, 0, 0.03);margin-bottom: 10px; margin-top: 10px;" *ngFor="let item of relContent; let i = index" [cdkDragDisabled]="!contentEdit" cdkDrag>
    <div [ngStyle]="{background: 'url(' + item.img + ')', 'background-size': 'cover', 'background-repeat': 'no-repeat', 'height': '200px'}" style="flex: 1"></div>
    <div style="display: flex; flex-direction: column; padding-left: 20px; flex: 4; position: relative;">
      <span class="material-icons" cdkDragHandle style="position: absolute; top: 10px; right: 10px; cursor: pointer">drag_indicator</span>
        <h3
        style="width: calc(100% - 40px);"
        [contentEditable]="contentEdit"
        [textContent]="getTranslateText(item.headline)"
        (input)="updateText($event.target.textContent, item.headline)">
        {{item.headline}}</h3>
        <p
        [contentEditable]="contentEdit"
        [textContent]="getTranslateText(item.text)"
        (input)="updateText($event.target.textContent, item.text)">
        {{item.text}}</p>
        <gmm-input
        style="flex: 1;"
          [(model)]="item.img"
          [placeholder]="'Image Link'"
          [label]="'Image Link'"
          [name]="'Image Link'"></gmm-input>
          <div style="display: flex; align-self: flex-end;">
            <gmm-action-button (buttonClicked)="deleteContent(i)" [icon]="'delete'" [color]="'accent'" [size]="'50px'" [matTooltip]="'Delete Item'"></gmm-action-button>
          </div>
        </div>
      </div>
      <div style="display: flex; align-self: center;">
        <gmm-action-button (buttonClicked)="newContent()" [icon]="'plus'" [size]="'50px'" [matTooltip]="'Add this content'" style="margin-left: auto; margin-right: auto;"></gmm-action-button>
      </div>
</div>

