import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentDataGuard } from '@shared/guards/content-data.guard';
import { environment } from 'src/environments/environment';
import { AuthCallbackComponent } from './auth/components/auth-callback.component';
import { AuthGuard } from './auth/services/auth-guard.service';

/* eslint-disable */
const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    data: { animation: 'admin' },
    canActivate: [
      ...(environment.isSharepoint ? [] : [AuthGuard]),
      ContentDataGuard
    ]
  },
  {
    path: 'form',
    loadChildren: () =>
      import('./form-elements/form-elements.module').then(
        (m) => m.FormElementsModule
      ),
    data: { animation: 'general' },
    canActivate: [
      ...(environment.isSharepoint ? [] : [AuthGuard]),
      ContentDataGuard
    ]
  },
  {
    path: 'not-found',
    loadChildren: () =>
      import('./error/error.module').then((m) => m.ErrorModule),
    data: { animation: 'general' }
  },
  ...(!environment.isSharepoint
    ? [{ path: 'auth/callback', component: AuthCallbackComponent }]
    : []),
  {
    path: '**',
    loadChildren: () =>
      import('./general-content-create/general-content-create.module').then(
        (m) => m.GeneralContentCreateModule
      ),
    data: { animation: 'general' },
    canActivate: [
      ...(environment.isSharepoint ? [] : [AuthGuard]),
      ContentDataGuard
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      useHash: environment.isSharepoint
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
