import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { ContentDataService } from 'src/app/general-content-create/services/content.data';
import { TextChanges } from 'src/app/shared/operations/text-changes.operations';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'gmm-related-content',
  templateUrl: './related-content.component.html',
  styleUrls: ['./related-content.component.scss']
})
export class RelatedContentComponent implements OnInit {
  @Input() contentEdit = false;
  @Input() translationFile;
  relContent = [];

  constructor(
    private translate: TranslateService,
    private contentData: ContentDataService
  ) {}

  ngOnInit() {
    this.contentData.staticContent.subscribe((data) => {
      if (data) {
        const relContent = data.content['related-content'];
        if (this.contentEdit) {
          this.relContent = JSON.parse(JSON.stringify(relContent));
        } else {
          this.relContent = relContent;
        }
      }
    });
  }

  drop(event: CdkDragDrop<string[]>, list) {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  getTranslateText(text) {
    if (this.contentEdit) {
      const textSplit = text.split('.');
      let transHolder = JSON.parse(JSON.stringify(this.translationFile));
      for (const itm of textSplit) {
        transHolder = transHolder[itm] ? transHolder[itm] : text;
        if (transHolder === text) {
          return transHolder;
        }
      }
      return transHolder;
    }
    return this.translate.instant(text);
  }

  updateText(change, text) {
    TextChanges.updateText(this.translationFile, change, text);
  }

  deleteContent(index) {
    this.relContent.splice(index, 1);
  }

  newContent() {
    this.relContent.push({
      img:
        'https://media.istockphoto.com/photos/world-connection-concept-picture-id943018452',
      headline: 'content.related-content.headline-' + uuid().split('-')[0],
      text: 'content.related-content.text-' + uuid().split('-')[0]
    });
  }
}
