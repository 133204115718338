import { SharedModule } from '@shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateSpoLoader } from '@shared/translate-spo-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from '@shared/helpers/customRouteReuseStrategy';
import { COMPONENTS } from '@static/index';
import { TokenInjector } from './auth/services/token.injector.service';
import { OAuthModule } from 'angular-oauth2-oidc';

const createTranslateLoader = (http: HttpClient) => {
  if (environment.isSharepoint) {
    return new TranslateSpoLoader();
  } else {
    return new TranslateHttpLoader(
      http,
      `https://d1nx9hpodl5uv8.cloudfront.net/${environment.project}/`,
      '.json'
    );
  }
};

@NgModule({
  declarations: [COMPONENTS, AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot()
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInjector, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
