<div class="mod-menu mod-menu--postlogin js-menu" (click)="closeMenu()">
  <div class="m-content" (click)="$event.stopPropagation()">
    <div class="m-navigation js-navigation">
      <div class="m-headline" *ngIf="menuLanguage">{{'LanguageSettings' | translate}}</div>
      <div class="m-headline" *ngIf="menuMenu">{{'menu' | translate}}</div> <!--[ngClass]="{center: headlineCenter}"-->
      <div class="mod-close-page mod-close-page--clean mod-close-page--dark" (click)="closeMenu()">
        <div class="m-close-icon"></div>
      </div>
    </div>
    <div class="m-content js-content">

      <gmm-menu-subcomponents class="m-layer js-layer" *ngIf="menuLanguage" style="height: 100%"></gmm-menu-subcomponents>

      <div class="m-layer js-layer" [ngStyle]="layerHeight" *ngIf="menuMenu">
        <div class="sideMenu" [ngStyle]="layerHeight">
          <ul>
            <li *ngFor="let navItem of navItems">
              <span>
                <a [routerLink]="[navItem.Href]" (click)="closeMenu()" style="text-decoration: none">{{navItem.Name | translate}}
                  <span *ngIf="navItem.HasNotifications" class="sideMenu-notification">{{navItem.NotificationValue}}</span>
                </a>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
