<div style="display: flex; flex-direction: column;">
  <gmm-top-bar class="lay-wrapper lay-wrapper--header"></gmm-top-bar>
  <!-- <reqbug-overlay-package applicationID="bfdiofds8-543jlkgf9-643" applicationName="Sierra"></reqbug-overlay-package> -->
  <div class="lay-wrapper lay-wrapper--main" style="padding-bottom: 0px; flex: 1; min-height: calc(100vh - 179px);">
    <router-outlet></router-outlet>
  </div>
  <gmm-related-content></gmm-related-content>
  <gmm-links-info></gmm-links-info>
  <gmm-footer class="lay-wrapper lay-wrapper--footer js-footer"></gmm-footer>
</div>
